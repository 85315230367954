import { Box } from "@mui/material";
import fileIcon from "assets/illustrations/file.jpg";

interface IImageTableRowItemProps {
  image: string;
}

const ImageTableRowItem = ({ image }: IImageTableRowItemProps) => {
  return (
    <Box
      sx={{
        width: { xs: "30vw", sm: "20vw", md: "10vw", lg: "100px" },
        height: { xs: "30vw", sm: "20vw", md: "10vw", lg: "100px" },
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <img
        src={image || fileIcon}
        alt="Product"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </Box>
  );
};

export default ImageTableRowItem;
