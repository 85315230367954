import { TenantContext } from "../context/TenantContext";
import { useContext } from "react";

const useTenant = () => {
  const tenant = useContext(TenantContext);
  if (!tenant) {
    throw new Error("useTenant must be used within TenantProvider");
  }
  return tenant;
};

export default useTenant;
