import { ITenant, TenantStatus } from "interfaces/graphql";

import { createContext } from "react";
import useFetchTenantDetails from "../hooks/useFetchTenantDetails";

interface ITenantContext {
  currentTenant: ITenant;
  auditInProgress: boolean;
  loading: boolean;
  error: any;
}

const TenantContext = createContext<ITenantContext | undefined>(undefined);

const TenantProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentTenant, loading, error } = useFetchTenantDetails();

  const auditInProgress = currentTenant.statuses.includes(
    TenantStatus.StockAuditInProgress
  );

  return (
    <TenantContext.Provider
      value={{ currentTenant, auditInProgress, loading, error }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export { TenantContext, TenantProvider };
