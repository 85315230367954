import { ITenant } from "interfaces/graphql";

export const emptyCurrentTenant: ITenant = {
  externalId: "",
  statuses: [],
  id: "",
  logoUrl: "",
  name: "",
  ordersEmail: "",
  ordersEmailName: "",
};
